/**
 * Changed navigate calls to have "/" at start, and also replace state in history
 * @see https://github.com/jxnblk/mdx-deck/pull/674
 */
import { navigate } from "@reach/router";

const nextSlide = ({ slug, length, index, setState }) => {
  const n = index + 1;
  if (n >= length) return;
  navigate("/" + [slug, n].join("/"), { replace: true });
  setState({ step: 0 });
};

export const next = context => {
  const { steps, step, setState } = context;
  if (!steps || step >= steps) return nextSlide(context);
  setState({ step: step + 1 });
};

const previousSlide = ({ slug, index, metadata, setState }) => {
  const n = index - 1;
  if (n < 0) return;
  navigate("/" + [slug, n].join("/"), { replace: true });
  const { steps = 0 } = metadata[n] || {};
  setState({ step: steps });
};

export const previous = context => {
  const { steps, step, setState } = context;
  if (steps && step > 0) {
    return setState({ step: step - 1 });
  }
  previousSlide(context);
};
