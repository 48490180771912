import { withPrefix } from "gatsby";

/**
 * @param {string} url
 */
export default function(url) {
  if (url.startsWith("#") || url.startsWith("http")) {
    return url;
  }
  return withPrefix(url);
}
