import React from "react";

/**
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
 *  html: string
 * }} param0
 */
export default function RawHtml({ html, ...rest }) {
  if (!html) {
    return null;
  }
  return <div {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
}
