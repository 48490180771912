import React from "react";
import GatsbyImg from "gatsby-image";
import { graphql } from "gatsby";

/**
 * @see https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
 * @param {import('gatsby-image').GatsbyImageProps & {
 *  src?: string,
 * }} props
 */
const Img = props => {
  let normalizedProps = props;
  // @ts-ignore
  const maxWidth = props.fluid?.presentationWidth;

  if (maxWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth,
        margin: "0 auto", // Used to center the image
      },
    };
  }

  // Fallback to src if fluid and fixed could not be found
  if (!props.fixed && !props.fluid && props.src) {
    const { src, alt, title, className, style } = props;

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img {...{ src, alt, title, className, style }} />
    );
  }

  return <GatsbyImg {...normalizedProps} />;
};

export const query = graphql`
  fragment FixedImg64 on ImageSharpFixed {
    ...GatsbyImageSharpFixed_withWebp
  }
  fragment FixedImg on ImageSharpFixed {
    ...GatsbyImageSharpFixed_withWebp_tracedSVG
  }
  fragment FluidImg on ImageSharpFluid {
    ...GatsbyImageSharpFluid_withWebp_tracedSVG
    presentationWidth
  }
  fragment FluidImg64 on ImageSharpFluid {
    ...GatsbyImageSharpFluid_withWebp
    presentationWidth
  }
`;

export default Img;
