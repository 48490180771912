import React from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

/**
 *
 * @param {import('@fortawesome/fontawesome-common-types').IconDefinition | import('@primer/octicons-react').Icon} icon
 * @returns {icon is import('@fortawesome/fontawesome-common-types').IconDefinition}
 */
function isFA(icon) {
  // @ts-ignore
  return !!icon.icon;
}

/**
 * @see https://github.com/FortAwesome/react-fontawesome/issues/70#issuecomment-381445075
 *
 * @param {{
 *   icon: import('@fortawesome/fontawesome-common-types').IconDefinition | import('@primer/octicons-react').Icon,
 *   className?: string,
 *   fontSize?: string,
 *   style?: import('react').CSSProperties,
 *   height?: string,
 *   name?: string,
 * }} props
 */
const Icon = props => {
  let {
    className = "",
    fontSize = "16px",
    height = "1em",
    style,
    icon: Icon,
    name,
  } = props;

  if (!Icon) {
    console.error("Icon not sent", name);
    Icon = faQuestionCircle;
  }

  // FontAwesome
  if (isFA(Icon))
    return (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix={Icon.prefix}
        data-icon={Icon.iconName}
        className={`svg-inline--fa fa-${Icon.iconName} fa-w-16 ${className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={style}
        fontSize={fontSize}
        height={height}
      >
        <path fill="currentColor" d={Icon.icon[4].toString()}></path>
      </svg>
    );

  // OctIcon
  return <Icon className="octicon" />;
};

export default Icon;
