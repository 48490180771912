import { Notes, Image, Split, Appear, Invert } from 'gatsby-theme-mdx-deck';
import selected from '@mdx-deck/themes/future';
import { vsDark as codeTheme } from "@code-surfer/themes";
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Icon } from '~utils';
import { CodeSurfer, CodeSurferColumns, Step, Left, Small, Tomato, Center } from '~components/DeckHelpers';
import React from 'react';
export default {
  Notes,
  Image,
  Split,
  Appear,
  Invert,
  selected,
  codeTheme,
  faGithub,
  faTwitter,
  Icon,
  CodeSurfer,
  CodeSurferColumns,
  Step,
  Left,
  Small,
  Tomato,
  Center,
  React
};