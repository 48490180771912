import React from "react";
import { Link as GatsbyLink } from "gatsby";

/**
 * @param {import('gatsby').GatsbyLinkProps & {
 *  external?: boolean,
 * }} param0
 */
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  external,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to) && !external;

  // Use <a> for external links
  if (!internal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...other}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
