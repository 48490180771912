import React, { useState, useEffect } from "react";

/**
 * @see https://www.gatsbyjs.org/docs/mdx/importing-and-using-components/#lazy-loading-components
 */
const LazyCodeSurfer = props => {
  const [CodeSurferExports, setExports] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    import(
      /* webpackChunkName: "code-surfer" */
      "code-surfer"
    )
      .then(res => {
        setExports(res);
      })
      .catch(err => {
        setErrors(err);
        console.error("[LazyCodeSurfer]", err);
      });
  }, []);

  if (!CodeSurferExports) {
    return <>Loading ....</>;
  }

  if (errors) {
    return <>Could not load Code-Surfer</>;
  }

  const Component = CodeSurferExports[props.component];
  return <Component {...props.props} />;
};

export const CodeSurfer = props => (
  <LazyCodeSurfer props={props} component="CodeSurfer" />
);

export const CodeSurferColumns = props => (
  <LazyCodeSurfer props={props} component="CodeSurferColumns" />
);

export const Step = props => <LazyCodeSurfer props={props} component="Step" />;

export const Small = ({ children }) => (
  <div
    style={{
      width: "60vw",
      height: "60vw",
      justifyContent: "center",
      textAlign: "center",
    }}
  >
    {children}
  </div>
);

export const Left = ({ children }) => (
  <div
    style={{
      textAlign: "left",
    }}
  >
    {children}
  </div>
);

export const Tomato = ({ children }) => (
  <strong
    style={{
      color: "tomato",
    }}
  >
    {children}
  </strong>
);

export const Color = (color = "tomato") => ({ children }) => (
  <strong
    style={{
      color,
    }}
  >
    {children}
  </strong>
);

export const Center = ({ children }) => (
  <div style={{ textAlign: "center" }}>{children}</div>
);

export default {
  Center,
  Small,
  Left,
  Tomato,
};
