export { default as RawHtml } from "./RawHtml";
export { default as safePrefix } from "./safePrefix";
export { default as Img } from "./Img";
export { default as Link } from "./Link";
export { default as Icon } from "./Icon";

/**
 * @param {string} str
 * @param {number} [maxLength]
 */
function truncate(str, maxLength = 100) {
  if (str.length < maxLength) return str;
  return str.slice(0, maxLength) + "...";
}

export { truncate };
