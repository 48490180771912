module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-import-code"],"remarkPlugins":[null,null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-mdx-deck/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"slides","basePath":"slides"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#9371e6","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rohit Gohri's Blog","short_name":"Rohit's Blog","description":"Personal Blog/Website of Rohit Gohri to pen down some thoughts","lang":"en","start_url":"/","display":"standalone","icon":"src/data/images/maskable_icon_x1.png","icon_options":{"purpose":"any maskable"},"background_color":"#959ea9","theme_color":"#9371e6","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about","/resume"],"workboxConfig":{"importWorkboxFrom":"local","globPatterns":["**/*.{js,jpg,png,ico,webp,gif,css,pdf}"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
