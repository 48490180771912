/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 */
import smoothscroll from "smoothscroll-polyfill";
import reframe from "reframe.js/dist/reframe.es";
import "react-toggle/style.css";
import "~sass/index.scss";

/** @type {import('gatsby').GatsbyBrowser['onInitialClientRender']} */
export const onInitialClientRender = () => {
  smoothscroll.polyfill();
};

/** @type {import('gatsby').GatsbyBrowser['onRouteUpdate']} */
export const onRouteUpdate = ({ location, prevLocation }) => {
  try {
    // Responsive video embeds
    var videoEmbeds = [
      'iframe[src*="youtube.com"]',
      'iframe[src*="vimeo.com"]',
    ];
    reframe(videoEmbeds.join(","));

    const isMdxSlide = path => path.includes("/slides/") && path !== "/slides/";

    if (isMdxSlide(location.pathname)) {
      document.documentElement.id = "";
    } else {
      document.documentElement.id = "root-el";
    }
  } catch (err) {
    //
  }
};
